.col-auto {
    /* background-color: #430264 ; */

    height: 100%;
    width: 100%;
    padding: 0%;
}

.nav-link {
    color: var(--sidebar-text-clr);

}

.nav-link :hover {
    color: white;
}

#sidebar {
    /* background-color: #6084cc ; */
    background-color: var(--primary-color);
    position: fixed;
    width: 13%;
    padding-left: 0%;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    /* background-color: #d3d3d3; */
    background-color: var(--sidebar-active-clr);
    color: blue;
    /* color: black; */

}
img.logo1 {
    margin: auto;
    height: 100px;
    width: 80px;
    margin-bottom: 26%;
}

#Logout {
    background-color: var(--sidebar-active-clr);
    color: black;
    position: relative;
    top: 15vh;

}

