
.invoice{
  border : solid;
}

@media print{

    .invoice{
      margin-top: 4vh;
      height: max-content;
      border: none;
      height : 100vh
    }
    
    .itable{
      border : solid 1px;
      box-shadow:none;
    }
  
    table.table.table-paytyp {
      margin-top : 15vh;
      box-shadow : none;
    }
    
    .CD { 
      left: 3vw;
      font-size: medium;
      margin-top: 3vh;
    }
  
  
  .ComD {
    left: 9vh;
    font-size: medium;
    margin-top: 3vh;
  }
  
  
  thead{
    height: 2vh;
    background-color: white;
    color : black;
    border-color: black;
    border: solid;
  }
  
  th{
  height : 3vh
  }
  
  .Total{
    width : 35vw;
    left : 28vw;
    line-height: 8px;
  }
  
  table.table.table-total {
    width: fit-content;
    position: relative;
    top : 2vh;
    left: 78vw;
    border-collapse: separate ;
    box-shadow: none;
  }
  }