:root{
    /* --primary-color : #add8e6 ; */
    --primary-color : #4f7de3 ;
    --sidebar-text-clr : white;
    --sidebar-active-clr : white;
    --table-clr-odd : #e7e8ed;
    --table-clr-even : transparent;
    --form-color : #8899e7;
    --cardOT-clr :#30e050fd;
    --cardPT-clr : #bc7ce9;
    --cardCT-clr :#ff8307;
    --form--btn-clr : white;
    --background-clr : white;
}

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body{
    background-color: var(--background-clr);}

    .sidebar{
        width: 14%;
    }

    .Admin{
        width: 85%;
    }