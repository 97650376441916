.form{
	background-image: linear-gradient(to left,var(--primary-color) ,rgb(196, 196, 219) , var(--primary-color)) ;
    background-size: 400% 400%;
	animation: gradient 10s ease-out infinite;
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

#LoginType{
	font-size: 15px;
}

.img-fluid {
    height: 72vh;
    display: block;
    margin: auto;
	margin-top: 6vh;
}