.form-btn{
  color : white;
background-color: black;
}

.form-btn:hover{
  color : white;
 background-color: var(--primary-color);
}

.form-select {
  background-color: var(--background-clr);
}

.modal-content {
  border-radius: 15px;
  background-image: linear-gradient(to right, var(--primary-color), var(--form-color), var(--primary-color));
  color: white;
}

.header {
  margin-top: 1vh;
  margin-left: 0vw;

  /*                                         ADMIN CARD                                                       */
}

.card {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: black;
}

.crd3 {
  color: white;
}

.crd4 :hover {
  background-color:  var(--primary-color);
  color: white;
}

.crd4 :link {
  text-decoration: none;
}

.crd4 .active {
  background-color:  var(--primary-color);
  color: white;
}


.crd1 :hover {
  background-color:  var(--cardOT-clr);
  color: white;
}

.crd1 :link {
  text-decoration: none;
}

.crd1 .active {
  background-color:  var(--cardOT-clr);
  color: white;
}

.crd2 :hover {
  background-color:  var(--cardPT-clr);
  color: white;
}

.crd2 :link {
  text-decoration: none;
}

.crd2 .active {
  background-color:  var(--cardPT-clr);
  color: white;
}

.crd3 :hover {
  background-color: var(--cardCT-clr);
  color: white;
}

.crd3 :link {
  text-decoration: none;
}

.crd3 .active {
  background-color: var(--cardCT-clr);
  color: white;
}

#Ad {
  background-color:  var(--primary-color);
  border-radius: 12px;
}

/*                                                       TABLE                                              */
.table {
  color: black;
  max-height: 10%;
  width: 80vw;
}

thead {
  background-color:  var(--primary-color);
  color: white;
  position: sticky;
  top: 0px;
  font-size: smaller;
  border-color:  var(--primary-color);
}

tbody {
  border-color:  var(--primary-color);
  font-size: smaller;
}

.table-striped>tbody>tr:nth-of-type(even)>* {
  --bs-table-accent-bg:var(--table-clr-even) ;
  color: var(--bs-table-striped-color);
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg:var(--table-clr-odd);
  color: var(--bs-table-striped-color);
}

/*      TABLE CONTAINER                    */

.container-ticket {
  overflow-y: auto;
  height: 66vh;
  width: fit-content;
  width: 87vw;
  margin-left: .5%;
}

.container-invoicelist {
  overflow-y: auto;
  height: 89vh;
  width: fit-content;
  width: 87vw;
  margin: 0px
}

.container-dislist {
  overflow-y: auto;
  height: 89vh;
  width: fit-content;
  width: 87vw;
  margin: 0px;
  border: solid;
}

input#Search {
  height: 25px;
  width: 10vw;
  font-size: revert;
}
