/* NAVBAR */
.navbar {
  background-color:  var(--primary-color);
  position: sticky;
  top: 0;
  color: var(--sidebar-text-clr);
  z-index: 1;
}

i.uil.uil-align-justify {
  color: white;
  font-size: 2rem;
}


#lgout {
  background-color: white;
  border-radius: 16px;
  margin-left: 65vw;
  color:  var(--primary-color);
}

ul {
  width: max-content;
}

.navbar-nav .nav-link.active,
 .navbar-nav .show>.nav-link {
  background-color:var(--sidebar-active-clr);
  border-radius: 10px;
  color:  var(--primary-color);
}
                                                        /*  Gen Invoice Table */
.table.add-product-mobile{
  display: none;
}

/*                                                             INVOICE          */

header{
  display: flex;
  gap : 26%;
  align-items: center;
  
}

img.logo{
  height: 100px;
  margin-left: 6%;
  margin-bottom: 16px;
  margin-top: 16px;
  width : 80px;
}

.invoice {
  /* width: 95vw; */
  margin: auto;
  margin-top: 1vh;
}

.CD {
  display: flex;
  flex-direction: column;
  position: relative;
  left: 9vh;
}

.ComD {
  display: flex;
  flex-direction: column;
  position: relative;
  left: 31vh;
}

.Total {
  position: relative;
  line-height: 14px;
  width: 15vw;
  height: max-content;
  left: 27vw;
  top: 2vh;
}

.itable {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border: solid;
  border-color: black;
  font-size: larger;
}

.invoice-input {
  display: inline;
  width: 25%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin-top: 5px;
}

table.table.table-total {
  width: fit-content;
  box-shadow: none;
  position: relative;
  left: 67vw;
}

table.table.table-paytyp {
  box-shadow: none;
  margin: auto;
}


.container-paytyp {
  display: none;

}

.input {
  margin-left: 2px;
}

.paytyp-input {
    font-size: medium;
}
/*                                                       EMPLOYEE CARD                                                                 */

.card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.Empcrd1 :hover {
  background-color: var(--cardPT-clr) ;
  color: white;
}

.Empcrd1 :link {
  text-decoration: none;
}

.Empcrd1 .active {
  background-color: var(--cardPT-clr);
  color: white;
}

.Empcrd2 :hover {
  background-color: var(--cardCT-clr);
  color: white;
}

.Empcrd2 :link {
  text-decoration: none;
}

.Empcrd2 .active {
  background-color: var(--cardCT-clr);
  color: white;
}


/*                                                        TICKETS TABLE                                         */

.table {
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.bg {
  background-image: linear-gradient(to right, #9A616D, #d3d3d3, #e9bebe);
}




/*                                                FOR MOBILE DEVICES                                                                          */

@media screen and (max-width: 568px) {


  /* Navbar */
  .navbar-dark .navbar-nav .nav-link.active,
  .navbar-dark .navbar-nav .show>.nav-link {
    background-color: transparent;
    color: #d3d3d3;
  }

  .navbar-collapse {
    text-align: -webkit-center;
  }

  /*                                             EMPLOYEE CARD                                                                     */

  #lgout {
    margin-left: 0px;
    height: 34px;
    font-size: 13px;
  }

  #DisBtn {
    background-color: #eee7f4;
    color:  var(--primary-color);
    padding: 1px;
    font-size: small;
  }

  .Empcrd1 {
    display: flex;
    width: 41vw;
    height: 12vh;
    margin: right 5px;
    ;
  }

  .Empcrd2 {
    display: flex;
    width: 41vw;
    height: 12vh;
  }

  .Empcrd1 a:hover {
    height: 9vh;
  }

  .Empcrd2 a:hover {
    height: 9vh;
  }

  h5 {
    font-size: small;
  }

  .profile {
    width: 90vw;
    margin: auto;
    font-size: 3vw;
  }

  /*                                                          TICKET TABLE                                                                    */


  .container-ticket {
    width: 95vw;

  }

  .container-table {
    overflow-x: auto;
    margin-top: 20px;
  }

  .sno,
  .complain,
  .mail,
  .state,
  .zip,
  .country,
  .city,
  .status {
    display: none;
  }

  /*                                                            INVOICE                                                       */
  .paytyp-input {
    width: -webkit-fill-available;
  }
 

.form-select {
    font-size: 11px;
  }

  .invoice-input {
    display: inline;
    width: -webkit-fill-available;
  }

  .itable {
    font-size: 12px;
  }

  table.table.table-paytyp {
    display: none;
  }

  .container-paytyp {
    display: block;
    font-size: 11px;

  }

  table.table.table-total {
    font-size: 9px;
    left: 50vw
  }

  .CD {
    font-size: xx-small;
    left: 0vw;
    width: 1vw;
    margin: 0px;
    padding: 0px;
  }

  .ComD {
    font-size: xx-small;
    left: 5vw;
    margin: 0px;
    padding: 0px;
  }

  #validate {
    margin-top: 10px;
  }

  .input {
    margin-top: 10px;
    margin-bottom: 1px;
  }
                                                /*  Gen Invoice Table */
  .table.add-product{
    display: none;
  }
  .table.add-product-mobile{
    display : table
  }
}


/*                                                   INVOICE PRINT                                                       */

@media print {

  img.logo{
    margin-left: 4%;
    margin-bottom : 10px;
  }

  .invoice {
    margin-top: 4vh;
    height: fit-content;
  }

  .itable {
    border: solid 1px;
    box-shadow: none;
  }

  table.table.table-paytyp {
    margin-top: 15vh;
    box-shadow: none;
  }

  .CD {
    left: 0.5px;
    font-size: medium;
    margin-top: 3vh;
  }


  .ComD {
    left: 8vh;
    font-size: medium;
    margin-top: 3vh;
  }


  thead {
    height: 2vh;
    background-color: white;
    color: black;
    border-color: black;
    border: solid;
  }

  .Total {
    width: 35vw;
    left: 28vw;
    line-height: 8px;
  }

  table.table.table-total {
    width: fit-content;
    position: relative;
    left: 75vw;
    border-collapse: separate;
    box-shadow: none;
  }

  td{
    height: fit-content;
  }

  @media screen and (max-width: 568px) {
    table.table.table-total {
      left: 60vw;
    }
  }
}