form{
    background-color : var( --form-color);
    padding: 3em;
    border-radius: 20px;
    border-left: 1px solid white;
    border-top: 1px solid white;
    backdrop-filter: blur(10px);
    box-shadow: 20px 20px 40px -6px rgba(0,0,0,0.2);
    text-align: left;
    position: relative;
    transition: all 0.2s ease-in-out;
    color: white;
   
}

#DisBtn{
    background-color: white;
    color: black;
    padding:8px;
}

input :hover {
       background-color :rgb(229, 32, 32);
      }

